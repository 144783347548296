import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './AddEditProductPage.css';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import TitleText from '../../components/TitleText/TitleText';
import AddImages from '../../components/AddImages/AddImages';
import FormInput from '../../components/FormInput/FormInput';
import WysiwygEditor from '../../components/WYSIWYGEditor/WYSIWYGEditor';
import { toast } from 'react-toastify';
import { addProduct, editProduct, getProduct } from '../../network/api';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import FormSelect from '../../components/FormSelect/FormSelect';
import { EditorState } from 'draft-js';
import DashboardLayout2 from '../../layout/DashBoardLayout2/DashBoardLayout2';
import Form2ColLayout from '../../layout/Form_2_ColLayout/Form_2_ColLayout';
import { useNavigate, useParams } from 'react-router-dom';
import CurrentImages from '../../components/CurrentImages/CurrentImages';
import BackButton from '../../components/BackButton/BackButton';
import Loader from '../../components/Loader/Loader';


const AddEditProductPage = () => {



  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());


  const [title, setTitle] = useState()
  const [price, setPrice] = useState()
  const [category, setCategory] = useState()
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [published, setPublished] = useState(false)
  const [mainImage, setMainImage] = useState()
  const [data, setData] = useState()
  const [mainLoader, setMainLoader] = useState(true)

  const { id } = useParams()

  const navigate = useNavigate()





  useEffect(() => {
    if (id) {
      setMainLoader(true)
      getProduct(id)
        .then((response) => {
          setData(response.data)
          setTitle(response.data.name)
          setPrice(response.data.price)
          setCategory(response.data.category)
          setPublished(response.data.published)
          setEditorState(response.data.description)

        })
        .catch(() => {
          toast.error("Something went wrong please retry", {
            onClose: () => {
              navigate("/markets")
            }
          })
        })
    }
    setMainLoader(false)

  }, [])




  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleLocationChange = (e) => {
    setPrice(e.target.value)
  }


  const handleCategoryChange = (e) => {
    setCategory(e.target.value)
  }

  const handleEditorStateChange = (e) => {
    setEditorState(e)
  }



  const selectedImages = (images) => {
    setImages(images)

  }

  const selectMainImage = (images) => {
    setMainImage(images[0])
  }

  const handlePublishChange = () => {
    setPublished(!published)
  }



  const getContent = (editorState) => {

    return editorState
  }

  const options = [{ "Cleaning": "Cleaning" }, { "Washing": "Washing" }, { "Carwash": "Carwash" }, { "House Cleaning": "House Cleaning" }, { "Washing": "Washing" }, { "Others": "Others" }]


  const handleCreateClassified = (e) => {
    e.preventDefault()


    const formData = new FormData();

    formData.append("name", title)
    formData.append("price", price)
    formData.append("published", published)
    formData.append("cateogry", category)
    formData.append("description", getContent(editorState))
    formData.append("published", published)


    if (data) {

      if (mainImage) {
        formData.append("main_image", mainImage)
      }

      if (images.length > 0) {

        for (let i = 0; i < images.length; i++) {
          formData.append('images[]', images[i]);
        }
      }
      setLoading(true)
      editProduct(id, formData)
        .then((rsp) => {
          toast.success("success product updated successfully", {
            onClose: () => {
              navigate("/markets")
            }
          })
        })
        .catch((err) => {
          toast.error(err)
        })





    } else {

      if (images.length <= 0) {

        toast.error('Kindly Add atleast one Product image to continue');

        return
      }

      if (!mainImage) {

        toast.error('Kindly Add Main image to continue');


        return
      }

      formData.append("main_image", mainImage)

      for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i]);
      }





      setLoading(true)
      addProduct(formData)
        .then(() => {

          toast.success('Product Added successfully', {
            onClose: () => {
              navigate('/markets')
            }
          })
        })
        .catch((error) => {
          toast.error(error)
          setLoading(false)
        })
    }
  }




  return (
    <DashboardLayout2>

      {mainLoader
        ?
        <div className='flex w-full h-full justify-center items-center'>
          <Loader></Loader>
        </div>
        :
        <div className=' px-6'>

          <BackButton path="/markets"></BackButton>


          <div

            class="flex  h-full w-full "
          >
            <div

              class="flex w-full xl:mx-40 2xl:mx-60  transition-all duration-300 ease-in-out ">
              <div
                class="auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current  outline-none dark:bg-neutral-600">
                <div
                  class="flex gap-2 flex-shrink-0 items-center  rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

                  <h5
                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                  >
                    Add / Edit Product
                  </h5>

                  <p className='text-gray-400 text-xs '>(items marked with <span className='text-red-500'>*</span> are Required)</p>
                </div>


                <div class="relative p-4">
                  <TitleText title='Main image'></TitleText>
                  {data &&
                    <CurrentImages images={[data.main_image_thumbnail_url]}></CurrentImages>
                  }
                  <AddImages setImages={selectMainImage} multiple={false}></AddImages>
                  <TitleText title='Other images(Multiple)'></TitleText>
                  {data &&
                    <CurrentImages images={data.image_thumbnails_urls}></CurrentImages>
                  }
                  <AddImages setImages={selectedImages}></AddImages>


                  <form onSubmit={handleCreateClassified}>

                    <TitleText title='Classifieds Details'></TitleText>
                    <div className='body flex flex-col gap-4'>

                      <Form2ColLayout>

                        <FormInput label="Title" onChange={handleTitleChange} required={true} value={title}></FormInput>

                        <FormInput label="Price" type='number' required={true} onChange={handleLocationChange} value={price}></FormInput>

                      </Form2ColLayout>
                      <div className='grid gap-4 w-full grid-cols-2'>
                        <FormSelect label="Category" options={options} onChange={handleCategoryChange} value={category}></FormSelect>
                      </div>

                      <label className='flex gap-2 my-4'>
                        <input type="checkbox" checked={published} onChange={handlePublishChange}></input>
                        published
                      </label>

                      <WysiwygEditor onchange={handleEditorStateChange} value={editorState}>

                      </WysiwygEditor>




                    </div>

                    <div className=' w-full flex justify-center items-center'>
                      <LoadingButton text="save" type="submit" loading={loading} />
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </DashboardLayout2>
  )
}

AddEditProductPage.propTypes = {};

AddEditProductPage.defaultProps = {};

export default AddEditProductPage;
