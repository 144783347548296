import React from 'react';
import PropTypes from 'prop-types';
import './RequiredMarker.css';

const RequiredMarker = () => (
  <p className="RequiredMarker text-red-500 text-left" data-testid="RequiredMarker">
    *
  </p>
);

RequiredMarker.propTypes = {};

RequiredMarker.defaultProps = {};

export default RequiredMarker;
