import axios from "axios";
import { BASE_URL } from "../utils/constants";
import { reactLocalStorage } from "reactjs-localstorage";

export const axiosInstance = axios.create({
    baseURL:BASE_URL
})


axiosInstance.interceptors.request.use((config)=>{

    const auth = reactLocalStorage.get("auth",null)
    if(auth){
        const parseAuth = JSON.parse(auth)
        config.headers ={
            ...config.headers,
            ...parseAuth
        }
    }
    
    return config
})


axiosInstance.interceptors.response.use((response)=>{

    if(response.headers["access-token"]){
        response.headers.delete("Content-Type")
        reactLocalStorage.set("auth",JSON.stringify(response.headers))
    }

    return response
})