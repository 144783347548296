import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './DashBoardPage.css';
import DashBoardStatCard from '../../components/DashBoardStatCard/DashBoardStatCard';
import TitleText from '../../components/TitleText/TitleText';
import NotificationStrip from '../../components/NotificationStrip/NotificationStrip';
import QuickActions from '../../components/QuickActions/QuickActions';
import Notifications from '../../components/Notifications/Notifications';
import QuickViews from '../../components/QuickViews/QuickViews';
import DashboardLayout from '../../layout/DashboardLayout/DashboardLayout';
import { getStats } from '../../network/api';
import { json } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const DashBoardPage = () => {

  const [data, setData] = useState({properties:0,classifieds:0,products:0,views:0})

  const chartData = {
    labels: [
      'Properties',
      'Classifieds',
      'Products'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [data.properties, data.classifieds, data.products],
      backgroundColor: [
        'rgb(16, 44, 82)',
        'rgb(54, 162, 120)',
        'rgb(47, 128, 237)'
      ],
      hoverOffset: 4
    }]
  };

  const options ={
    responsive:true,
    plugins:{
      legend:{
          position:"right",
          align:"center"
        
       
      }

    }
  }

  useEffect(() => {

    getStats()
      .then((response) => {

        setData(response.data)
        console.log(response.data)

      })
      .catch((error) => {
        // setStats(null)
        console.log(error)
      })

  }, [])


  var NumAbbr = require('number-abbreviate')

  var numAbbr = new NumAbbr()



  return (
    <DashboardLayout>

      {/* content */}


      {data &&

        <div className='Dash-Cards grid grid-cols-4 items-stretch  gap-4'>
          <DashBoardStatCard label='Properties' value={numAbbr.abbreviate(data.properties)}></DashBoardStatCard>
          <DashBoardStatCard label='Classifieds' value={numAbbr.abbreviate(data.classifieds)} color='#56CCF2'></DashBoardStatCard>
          <DashBoardStatCard label='Products' value={numAbbr.abbreviate(data.products)}></DashBoardStatCard>
          <DashBoardStatCard label='Views' value={numAbbr.abbreviate(data.views)}></DashBoardStatCard>
        </div>

      }

      <div className=' grid grid-cols-1  md:grid-cols-2 gap-8'>
        <div>
          <TitleText title='Trends'></TitleText>
          <div className='w-full h-full flex'>
            <div className='h-[300px] w-full flex justify-stretch items-stretch bg-blue-50 rounded px-8'>
              <Doughnut data={chartData} options={options} responsive={true}></Doughnut>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-2 h-full'>
          <TitleText title='Quick Views'></TitleText>
          <QuickViews></QuickViews>


        </div>
      </div>

      {/*  */}
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 mt-4'>
        <div className='Left mt-4 flex gap-2 flex-col'>

          <TitleText title='Notifications'></TitleText>
          <Notifications></Notifications>

        </div>

        <div className='Right mt-4 flex flex-col gap-2'>

          <TitleText title='Quick Actions'></TitleText>

          <QuickActions></QuickActions>

        </div>


      </div>

    </DashboardLayout>
  );
}

DashBoardPage.propTypes = {};

DashBoardPage.defaultProps = {};

export default DashBoardPage;
