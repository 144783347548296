import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './LoginPage.css';
import LoginForm from '../../components/LoginForm/LoginForm';
import { NavLink, redirect, useNavigate } from 'react-router-dom';
import SignUpForm from '../../components/SignUpForm/SignUpForm';
import { useColorScheme } from '@mui/material';
import { AuthContext } from '../../providers/AuthProvider';



const LoginPage = () => {

  
  const navigate = useNavigate()

  const {validateToken} = useContext(AuthContext)

  const [user, setUser] = useState("")
  const [signUp,setSignUp] = useState(false)


  const Auth = require("j-toker");


  useEffect(() => {
      const validate = async () =>{
        try {
          await validateToken()
        }catch{
         
        }

      }

      validate()
     
  },[navigate])

  
  const handleSignUp = ()=>{
    setSignUp(true)
  }

  const handleLogin = ()=>{
    setSignUp(false)
  }
  
  
  
  return(
  <div className="App  min-h-screen  flex justify-stretch w-full">
      <div className='Right bg-pri hidden w-full lg:flex justify-center items-center'>
        <img src='/login_bg.png' alt='log in background'></img>

      </div>
      <div className='Left flex flex-col justify-center items-center px-6 py-8 h-full min-h-screen w-full'>
        {signUp
        ?<SignUpForm handleLoginIn={handleLogin}></SignUpForm>
        :<LoginForm handleSignUp={handleSignUp}></LoginForm>
        }
          
      </div>



    </div>
);
}

LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;
