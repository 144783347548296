import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ClassffiedsPage.css';
import DashboardLayout from '../../layout/DashboardLayout/DashboardLayout';
import MUIDataTable from "mui-datatables";
import PublishedStatus from '../../components/PublishedStatus/PublishedStatus';
import ViewCounter from '../../components/ViewCounter/ViewCounter';
import TableActions from '../../components/TableActions/TableActions';
import ClassifiedsModal from '../../components/ClassifiedsModal/ClassifiedsModal';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { BASE_URL } from '../../utils/constants';
import { toast } from 'react-toastify';
import { deleteClassified, getAllClassifieds } from '../../network/api';
import EmptyState from '../../components/EmptyState/EmptyState';
import TitleText from '../../components/TitleText/TitleText';


const ClassffiedsPage = () => {

  const [data, setData] = useState();
  const [columns, setColums] = useState();
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();



  const del = (value) => {

    alert("Are you sure you want to delete this record")


    deleteClassified(value)
      .then(() => {

        toast.success('Property Deleted Succesfully ', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })

        setData(null)
        setReload(true)

      })
      .catch((error) => {

        // console.log('Delete request failed');
        // console.log(error);
        toast.error(error.response.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })

      })
  }

  const handleOnSave = () => {
    setReload(true)
    setData(null)
  }


  const ed = (value) => {
    navigate("edit/" + value)

  }

  useEffect(() => {

    setLoading(true)

    getAllClassifieds()

      .then((response) => {

        setData(response.data)
        setReload(false)
        setColums(
          [
            {
              name: "image_urls",
              label: "Photo",
              options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <div className='w-11 h-11 flex justify-center items-center'>
                      {value &&
                        <img src={value[0]} className=' w-full h-full rounded-lg' />
                      }
                    </div>
                  );
                }
              },
            },
            {
              name: "title",
              label: "Title",
              options: {
                sort: true,
              },
            },
            {
              name: "location",
              label: "Location",
              options: {
                sort: true,
              },
            },
            {
              name: "views",
              label: "Views",
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return <ViewCounter value={value}></ViewCounter>;
                }
              },
            },
            {
              name: "published",
              label: "published",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (<PublishedStatus value={value}></PublishedStatus>)
                }
              },
            },
            {
              name: "id",
              label: "Actions",
              options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (<TableActions ed={ed} del={del} value={value}></TableActions>)
                }
              },
            }
          ]
        )

      })
      .catch((error) => {

      }).finally(() => {
        setLoading(false)
      })

  }, [reload])




  const options = {
    tableRoot: {
      boxShadow: "none",
    },
    filterType: 'checkbox',
    elevation: 0,
    border: 'none',
    responsive: "simple",
    selectableRowsHeader: false,
    selectableRows: false 

  };


  return (
    <DashboardLayout>
      {/* <ClassifiedsModal onSave={handleOnSave}></ClassifiedsModal> */}
      <div className='flex w-full justify-between px-6'>

        <TitleText title='All Classifieds'></TitleText>

        <Link to="add">
          <button
            type="button"
            className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white  transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
            Add Classified
          </button>
        </Link>

      </div>
      

      <div className='h-full w-full flex text-default-color flex-col p-6'>
        {loading
          ?
          <Loader></Loader>
          : data == null ?

            <EmptyState></EmptyState>

            : <MUIDataTable
              data={data}
              elevation={0}
              columns={columns}
              options={options}
            />
        }

      </div>
    </DashboardLayout>
  );
}

ClassffiedsPage.propTypes = {};

ClassffiedsPage.defaultProps = {};

export default ClassffiedsPage;
