import React from 'react';
import PropTypes from 'prop-types';
import './TitleText.css';

const TitleText = ({title="title"}) => (
  <p className='Title text-pri font-bold mt-4 text-lg'>{title}</p>
);

TitleText.propTypes = {};

TitleText.defaultProps = {};

export default TitleText;
