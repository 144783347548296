import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AddProductModal.css';
import { EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import { Modal, initTE, Ripple } from "tw-elements";
import TitleText from '../TitleText/TitleText';
import AddImages from '../AddImages/AddImages';
import FormInput from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';
import WysiwygEditor from '../WYSIWYGEditor/WYSIWYGEditor';
import { toast } from 'react-toastify';
import draftToHtml from 'draftjs-to-html';
import { BASE_URL } from '../../utils/constants';
import { addProduct } from '../../network/api';
import LoadingButton from '../LoadingButton/LoadingButton';

const AddProductModal = ({ onSave }) => {
  const $ = require('jquery')


  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());


  const [title, setTitle] = useState(2000)
  const [price, setPrice] = useState()
  const [category, setCategory] = useState()
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [published,setPublished] = useState(false)
  const [mainImage,setMainImage] = useState()


  initTE({ Modal });

  const myModal = (new Modal(document.getElementById("product_modal")));
  // const dialog = (new Modal(document.getElementById("Dialog")));





  const handleTitleChange = (e) => [
    setTitle(e.target.value)
  ]

  const handleLocationChange = (e) => {
    setPrice(e.target.value)
  }


  const handleCategoryChange = (e) => {
    setCategory(e.target.value)
  }

  const handleEditorStateChange = (e) => {
    setEditorState(e)
  }



  const selectedImages = (images) => {
    setImages(images)

  }

  const selectMainImage = (images) => {
    setMainImage(images[0])
  }

  const handlePublishChange = () =>{
    setPublished(!published)
  }



  const getContent = (editorState) => {
   
    return editorState
  }

  const options = [{ 0: "Cleaning" }, { 1: "washing" }, { 2: "Carwash" }, { 3: "House Cleaning" }, { 4: "washing" }, { 5: "others" }]


  const handleCreateClassified = (e) => {
    e.preventDefault()


    const formData = new FormData();

    if (images.length <= 0) {

      toast.error('Kindly Add atleast one Product image to continue', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return
    }

    if (!mainImage){

      toast.error('Kindly Add Main image to continue', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });


      return
    }




    formData.append("name", title)
    formData.append("price", price)
    formData.append("published", published)
    // formData.append("cateogry", category)
    formData.append("description", getContent(editorState))
    formData.append("published",published)
    formData.append("main_image",mainImage)

    for (let i = 0; i < images.length; i++) {
      formData.append('images[]', images[i]);
    }

    setLoading(true)
    addProduct(formData)
      .then(() => {

        myModal.hide()
        toast.success('Product Added successfully', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        onSave()
      })
      .catch((error) => {
        toast.error(error, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <div className='flex w-full justify-between px-6'>

        <TitleText title='All Properties'></TitleText>


        <button
          type="button"
          className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-toggle="modal"
          data-te-target="#product_modal"
          data-te-ripple-init
          data-te-ripple-color="light">
          Add Product
        </button>
      </div>


      <div
        data-te-modal-init
        class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="product_modal"
        tabindex="-1"
        aria-labelledby="product_modal"
        aria-modal="true"
        role="dialog">
        <div
          data-te-modal-dialog-ref
          class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]">
          <div
            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div
              class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

              <h5
                class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                id="exampleModalLgLabel">
                Add Classified
              </h5>

              <button
                type="button"
                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>


            <div class="relative p-4">
              <TitleText title='Main image'></TitleText>
              <AddImages setImages={selectMainImage} multiple={false}></AddImages>
              <TitleText title='Other images(Multiple)'></TitleText>
              <AddImages setImages={selectedImages}></AddImages>


              <form onSubmit={handleCreateClassified}>

                <TitleText title='Classifieds Details'></TitleText>
                <div className='body flex flex-col gap-4'>

                  <FormInput label="Title" onChange={handleTitleChange} required={true} value={title}></FormInput>

                  <FormInput label="Price" type='number' required={true} onChange={handleLocationChange} value={price}></FormInput>

                  <div className='grid gap-4 w-full grid-cols-2'>
                    <FormSelect label="Category" options={options} onChange={handleCategoryChange} value={category}></FormSelect>
                  </div>

                  <label className='flex gap-2'>
                  <input type="checkbox" checked={published} onChange={handlePublishChange}></input>
                  published
                  </label>

                  <WysiwygEditor onchange={handleEditorStateChange} value={editorState}>

                  </WysiwygEditor>




                </div>

                <div className=' w-full flex justify-center items-center'>
                  <LoadingButton text="save" type="submit" loading={loading} />
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>




    </div>
  );
}
AddProductModal.propTypes = {};

AddProductModal.defaultProps = {};

export default AddProductModal;
