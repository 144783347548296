import React from 'react';
import PropTypes from 'prop-types';
import './CurrentImages.css';

const CurrentImages = ({images}) => (
  <div className="flex items-start justify-start">
  <div className="max-w-md w-full rounded-md">
      {images&&
      <div className="gap-4 h-auto grid grid-cols-5 rounded-lg py-2">
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt="Preview"
              className="w-60 h-16 object-cover border-2 border-pri rounded"
            />
            {/* <a
              href={previewImage}
              download={selectedFiles[index].name}
              className="block mt-2 text-sm font-medium text-blue-600 hover:text-blue-500"
            >
              Download
            </a> */}
          </div>
        ))}
      </div>
      }
  </div>
</div>
);

CurrentImages.propTypes = {};

CurrentImages.defaultProps = {};

export default CurrentImages;
