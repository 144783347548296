import React from 'react';
import PropTypes from 'prop-types';
import './QuickActions.css';
import { Link } from 'react-router-dom';

const QuickActions = () => (

  <div className='grid grid-cols-1 md:grid-cols-2 gap-4 h-full max-h-[300px] items-center bg-slate-50 rounded p-2'>

    <Link to="/markets">
    <div className='Add classified flex flex-col items-center justify-center p-8 bg-sec rounded-2xl min-h-[140px] gap-4 h-full '>
      <div>

        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 38.5C30.665 38.5 38.5 30.665 38.5 21C38.5 11.335 30.665 3.5 21 3.5C11.335 3.5 3.5 11.335 3.5 21C3.5 30.665 11.335 38.5 21 38.5Z" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21 14V28" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14 21H28" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <p className=' text-xl font-bold text-white text-center'>Add Property</p>

    </div>
    </Link>


    <Link to="/profile">
    <div className='Add classified flex flex-col items-center justify-center p-8 bg-pri rounded-2xl min-h-[140px] gap-4'>
      <div>

        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 38.5C30.665 38.5 38.5 30.665 38.5 21C38.5 11.335 30.665 3.5 21 3.5C11.335 3.5 3.5 11.335 3.5 21C3.5 30.665 11.335 38.5 21 38.5Z" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21 14V28" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14 21H28" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <p className=' text-xl font-bold text-white text-center'>Add Classified</p>

    </div>
    </Link>
  </div>

);

QuickActions.propTypes = {};

QuickActions.defaultProps = {};

export default QuickActions;
