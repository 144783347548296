import logo from './logo.svg';
import './App.css';
import LoginForm from './components/LoginForm/LoginForm';
import LoginPage from './pages/LoginPage/LoginPage';
import { Route, Routes } from 'react-router-dom';
import DashBoardPage from './pages/DashBoardPage/DashBoardPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import PropertiesPage from './pages/PropertiesPage/PropertiesPage';
import ClassffiedsPage from './pages/ClassffiedsPage/ClassffiedsPage';
import AddProfilePage from './pages/AddProfilePage/AddProfilePage';
import { BASE_URL } from './utils/constants';
import MarketsPage from './pages/MarketsPage/MarketsPage';
import { AuthProvider } from './providers/AuthProvider';
import AddEditClassifiedPage from './pages/AddEditClassifiedPage/AddEditClassifiedPage';
import AddEditProductPage from './pages/AddEditProductPage/AddEditProductPage';
import AddEditPropertyPage from './pages/AddEditPropertyPage/AddEditPropertyPage';


function App() {

  var Auth = require("j-toker")
  var $ = require("jquery")

  Auth.configure({
    apiUrl: BASE_URL,
    storage: "localStorage"
  });

  // $.ajaxSetup({
  //   beforeSend: function(xhr, settings) {
  //     // append outbound auth headers
  //     $.auth.appendAuthHeaders(xhr, settings);

  //     // now do whatever you want
  //   }
  // });

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<LoginPage></LoginPage>}></Route>
        <Route path="/dashboard" element={<DashBoardPage></DashBoardPage>}></Route>
        <Route path="/add_profile" element={<AddProfilePage></AddProfilePage>}></Route>
        
        <Route path="/properties" element={<PropertiesPage></PropertiesPage>}></Route>
        <Route path="/properties/add" element={<AddEditPropertyPage></AddEditPropertyPage>}></Route>
        <Route path="/properties/edit/:id" element={<AddEditPropertyPage></AddEditPropertyPage>}></Route>

        <Route path="/classifieds" element={<ClassffiedsPage></ClassffiedsPage>}></Route>
        <Route path="/classifieds/add" element={<AddEditClassifiedPage></AddEditClassifiedPage>}></Route>
        <Route path="/classifieds/edit/:id" element={<AddEditClassifiedPage></AddEditClassifiedPage>}></Route>

        <Route path="/markets" element={<MarketsPage></MarketsPage>}></Route>
        <Route path="/markets/add" element={<AddEditProductPage></AddEditProductPage>}></Route>
        <Route path="/markets/edit/:id" element={<AddEditProductPage></AddEditProductPage>}></Route>

        <Route path="/profile" element={<ProfilePage></ProfilePage>}></Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
