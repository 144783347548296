import React from 'react';
import PropTypes from 'prop-types';
import './BackButton.css';
import { Title } from 'chart.js';
import TitleText from '../TitleText/TitleText';
import { Link } from 'react-router-dom';

const BackButton = ({path}) => (
  <div className="BackButton flex gap-2 items-center my-10">
    <Link to={path}>
      <p className='font-bold text-2xl text-pri hover:text-blue-700' > &lt;  Back</p>
    </Link>
  </div>
);

BackButton.propTypes = {};

BackButton.defaultProps = {};

export default BackButton;
