import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PropTypes from 'prop-types';
import './AddImages.css';
import DropArea from "../DropArea/DropArea";


const fileTypes = ["JPG", "PNG","JPEG"];


const AddImages = ({setImages,multiple=true}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);




 
  const handleChange = (f) => {

   
    const files  = selectedFiles;
    if (multiple){
      files.push(f)
    }else{
      files[0]=f
    }
   
    console.log({...files})

    const previewImages = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = () => {
        previewImages.push(reader.result);
        if (previewImages.length === files.length) {
          setPreviewImages(previewImages);
        }
      };
      reader.readAsDataURL(file);
      // console.log({...file[0]})
    }
    setSelectedFiles(files);

    setImages(selectedFiles)
  };
  return (
    <div className="flex gap-4 flex-col">
      <FileUploader handleChange={handleChange}  name="file" types={fileTypes} children={<DropArea></DropArea>} required={true} />


      <div className="flex items-start justify-start">
        <div className="max-w-md w-full rounded-md">
      
            <div className="gap-4 h-auto grid grid-cols-5 rounded-lg py-2">
              {previewImages.map((previewImage, index) => (
                <div key={index}>
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="w-60 h-16 object-cover border-2 border-pri rounded"
                  />
                  {/* <a
                    href={previewImage}
                    download={selectedFiles[index].name}
                    className="block mt-2 text-sm font-medium text-blue-600 hover:text-blue-500"
                  >
                    Download
                  </a> */}
                </div>
              ))}
            </div>
        </div>
      </div>
    </div>
  );
}

AddImages.propTypes = {};

AddImages.defaultProps = {};

export default AddImages;
