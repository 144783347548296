import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import './MobileNav.css';
import MobileNavLink from '../MobileNavLink/MobileNavLink';
import { AuthContext } from '../../providers/AuthProvider';
const MobileNav = () => {
  const { logOut, validateToken, user } = useContext(AuthContext)


  const [show, setShow] = useState(false)


  const toggleShow = () => setShow(!show)



  const logout = () => {
    logOut()
  }



  return (

    <div class="">

      <div class="dropdown inline-block relative">
        <button href='#' onClick={toggleShow} class="font-semibold py-2 px-4 rounded inline-flex items-center" tabIndex="5" >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 10H7" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 6H3" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 14H3" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 18H7" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        {show &&
          <div class="bg white dropdown-menu absolute text-gray-700 pt-1 right-5 flex flex-col bg-[#f1f1f1] gap-1 rounded-s shadow-xl z-50 min-w-[200px]">
{/* 
<SidenavNavStrip path="/dashboard" text="Dashboard" />
              <SidenavNavStrip path="/properties" text="My Properties" />
              <SidenavNavStrip path="/Classifieds" text="My Classifieds" />
              <SidenavNavStrip path="/markets" text="Market" />
              <SidenavNavStrip path="/profile" text="Profile" />

            </div>
            <button onClick={logout} className='logout   bg-sec flex p-4 justify-center justify-self-end items-center text-white w-full'>
              Logout
            </button>
 */}


            <MobileNavLink text="Dashboard" path="/dashboard"></MobileNavLink>

            <MobileNavLink text="My Properties" path="/properties"></MobileNavLink>

            <MobileNavLink text="My Classifieds" path="/classifieds"></MobileNavLink>

            <MobileNavLink text="My Markets" path="/markets"></MobileNavLink>

            <MobileNavLink text="My Profile" path="/profile"></MobileNavLink>


            {/* <MobileNavLink text="About" path="/about"></MobileNavLink> */}

        {/* <MobileNavLink text="Contact" path="/contact"></MobileNavLink> */}

        <div className='p-2 h-full my-4'>
          
            <button
            onClick={logout}
              type="button"
              class=" w-full whitespace-nowrap overflow-ellipsis rounded-full text-white bg-sec tx px-8 pb-3 pt-3.5 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out  focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200">
              logout
            </button>
    
        </div>
      </div>
        }

    </div>

    </div >
  );
}

MobileNav.propTypes = {};

MobileNav.defaultProps = {};

export default MobileNav;
