import React from 'react';
import PropTypes from 'prop-types';
import './SidenavNavStrip.css';
import { NavLink } from 'react-router-dom';

const SidenavNavStrip = ({text,path}) => {

  const classNameFunc = ({ isActive }) => (isActive ? "py-4 px-6 block bg-white text-pri" : "py-4 px-6 block bg-pri text-white");
  return(
  <NavLink to={path} className={classNameFunc}>

    <p className='ml-8'>{text}</p>

  </NavLink>
);
}

SidenavNavStrip.propTypes = {};

SidenavNavStrip.defaultProps = {};

export default SidenavNavStrip;
