import React from 'react';
import PropTypes from 'prop-types';
import './TableActions.css';

const TableActions = ({ed,del,value}) => (
  <div className='flex gap-8 justify-center'>

              <div onClick={()=>ed(value)} className=' cursor-pointer'>
                <svg className=' hover:stroke-blue-600 stroke-blue-400' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 16.6665H17.5"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.75 2.91669C14.0815 2.58517 14.5312 2.39893 15 2.39893C15.2321 2.39893 15.462 2.44465 15.6765 2.53349C15.891 2.62233 16.0858 2.75254 16.25 2.91669C16.4142 3.08084 16.5444 3.27572 16.6332 3.4902C16.722 3.70467 16.7678 3.93455 16.7678 4.16669C16.7678 4.39884 16.722 4.62871 16.6332 4.84319C16.5444 5.05766 16.4142 5.25254 16.25 5.41669L5.83333 15.8334L2.5 16.6667L3.33333 13.3334L13.75 2.91669Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

              </div>


              <div onClick={()=>del(value)} className=' cursor-pointer' >
                <svg className=' hover:stroke-red-600 stroke-red-400' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.5 5H4.16667H17.5"  stroke-opacity="0.3"  stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.8337 4.99984V16.6665C15.8337 17.1085 15.6581 17.5325 15.3455 17.845C15.0329 18.1576 14.609 18.3332 14.167 18.3332H5.83366C5.39163 18.3332 4.96771 18.1576 4.65515 17.845C4.34259 17.5325 4.16699 17.1085 4.16699 16.6665V4.99984M6.66699 4.99984V3.33317C6.66699 2.89114 6.84259 2.46722 7.15515 2.15466C7.46771 1.8421 7.89163 1.6665 8.33366 1.6665H11.667C12.109 1.6665 12.5329 1.8421 12.8455 2.15466C13.1581 2.46722 13.3337 2.89114 13.3337 3.33317V4.99984"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.33301 9.1665V14.1665"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.667 9.1665V14.1665"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

              </div>

            </div>
);

TableActions.propTypes = {};

TableActions.defaultProps = {};

export default TableActions;
