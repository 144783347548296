import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, redirect, useNavigate } from 'react-router-dom';
import './SignUpForm.css';
import FormInput from '../FormInput/FormInput';
import { AuthContext } from '../../providers/AuthProvider';
import { TailSpin } from 'react-loader-spinner';

const SignUpForm = ({handleLoginIn}) => {

  const Auth = require("j-toker")

  const navigate = useNavigate();

  const {signUp} = useContext(AuthContext)
  const [loading,setLoading] = useState()



  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")


  const handleEmailInput = (e) => {

    setEmail(e.target.value)
  }

  const handlePasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const handlePasswordConfirmInput = (e) => {
    setPasswordConfirm(e.target.value)
  }


  

  const onSubmit = async(e) => {
    e.preventDefault()

    setLoading(true)
    await signUp(email,password)
    setLoading(false)
    

  }




  return (
    <div className='w-full md:max-w-sm flex flex-col items-center gap-4'>
      <div className='Logo'>
        <img src='./logo.png' alt='logo' />
      </div>
      <form className='w-full flex gap-4 flex-col ' onSubmit={onSubmit}>
        {/* email */}
        <FormInput label="email" value={email} onChange={handleEmailInput} ></FormInput>
        {/* password */}
        <FormInput label="password" value={password} onChange={handlePasswordInput} type='password'></FormInput>

        <FormInput label="Confirm password" value={passwordConfirm} onChange={handlePasswordConfirmInput} type='password'></FormInput>

        <div  className='w-full flex items-center justify-center'>

        {loading ?

          <TailSpin
            height="37"
            width="37"
            color="#2f80ed"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          :
          <button
            type="submit"
            className="inline-block mt-4 w-full  rounded py-4  bg-pri px-6 pb-2 pt-2.5 text-md font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">

            Create Account

          </button>
        }


        </div>

        <div className='flex justify-center gap-2 items-center mt-2' >

          <p className=' text-default-color' onClick={handleLoginIn}>Back to Login</p>

        </div>



      </form>

    </div>
  );

}

SignUpForm.propTypes = {};

SignUpForm.defaultProps = {};

export default SignUpForm;
