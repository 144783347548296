import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './AddProfilePage.css';
import {
  Ripple,
  initTE,
  Modal,
} from "tw-elements";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import WysiwygEditor from '../../components/WYSIWYGEditor/WYSIWYGEditor';
import TitleText from '../../components/TitleText/TitleText';
import FormInput from '../../components/FormInput/FormInput';
import Form2ColLayout from '../../layout/Form_2_ColLayout/Form_2_ColLayout';
import { ToastContainer, toast } from 'react-toastify';
// import { EditorState } from 'react-draft-wysiwyg';
import { useNavigate } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { BASE_URL } from '../../utils/constants';
import { AuthContext } from '../../providers/AuthProvider';
import { addUserProfile } from '../../network/api';
import LoadingButton from '../../components/LoadingButton/LoadingButton';

const AddProfilePage = () => {

  const {validateToken} = useContext(AuthContext)


  const navigate = useNavigate()
  const $ = require("jquery")
 

useEffect(()=>{
  
  const check = async() =>{


      try{
        await validateToken()
        
      }catch(err){
        navigate("/")
      }
    }

  check();
}, [navigate])

const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

useEffect(() => {

  initTE({ Ripple, Modal });
}, [])


const myModal = (new Modal(document.getElementById("cModal")));
// const dialog = (new Modal(document.getElementById("Dialog")));


const [name, setName] = useState("")
const [altEmail, setAltEmail] = useState("")
const [phone, setPhone] = useState("")
const [altPhone, setAltPhone] = useState("")
const [city, setCity] = useState("")
const [town, setTown] = useState("")
const [area, setArea] = useState("")
const [street, setStreet] = useState("")
const [image, setImage] = useState("")
const [loading,setLoading] = useState(false)

const handleNameChange = (e) => {
  setName(e.target.value)
}

const handleAltEmailChange = (e) => {
  setAltEmail(e.target.value)
}

const handlePhoneChange = (e) => {
  setPhone(e.target.value)
}

const handleAltPhoneChange = (e) => {
  setAltPhone(e.target.value)
}

const handleCityChange = (e) => {
  setCity(e.target.value)
}


const getContent = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const rawContentState = convertToRaw(contentState);
  const htmlContent = draftToHtml(rawContentState);
  return htmlContent
}


const onSave = () => {

  navigate("/dashboard")

}


const handleImageChange = (e) => {
  const file = e.target.files[0];
  setImage(file);
};



const handleSubmit = (e) => {
  e.preventDefault()


  const formData = new FormData();

  if (image == null) {

    toast.error('Kindly Add atleast one Property image to continue', {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    return
  }




  formData.append("username", name)
  formData.append("alt_email", altEmail)
  formData.append("phone", phone)
  formData.append("alt_phone", altPhone)
  formData.append("city", city)
  formData.append("town", town)
  formData.append("area", area)
  formData.append("street", street)
  formData.append("about", getContent(editorState))
  formData.append('image', image);






  // var formData = new FormData($(this)[0]);
  // $.ajax({
  //   url: `${BASE_URL}/user_profiles`,
  //   type: 'POST',
  //   data: formData,
  //   processData: false,
  //   contentType: false,
  //   success: function (response) {
  //     console.log(response);
  //     myModal.hide()
  //     toast.success('Property Deleted Succesfully ', {
  //       position: "bottom-center",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     })
  //     onSave()
  //   },
  //   error: function (xhr, status, error) {
  //     console.log(xhr.responseText);
  //     toast.error(error, {
  //       position: "bottom-center",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     })

  //   }
  // });

  setLoading(true)
  addUserProfile(formData)
  .then(()=>{
    toast.success('Property Deleted Succesfully ', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          onSave()
  })
  .catch((error)=>{

    toast.error(error, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })

  }).finally(()=>{
    setLoading(false)
  })


}


return (
  <form onSubmit={handleSubmit}>
    <div className="AddProfilePage max-w-3xl flex flex-col justify-center items-center w-full my-24  mx-auto" data-testid="AddProfilePage">
      <h1 className=' text-4xl font-bold text-center text-pri mx-auto'>Update Profile</h1>
      <div className='steps flex justify-center mt-10 items-center'>
        <div className='p-[2px] w-8 h-8 rounded-full bg-pri font-bold text-white  flex justify-center items-center'>
          1
        </div>
        <div className='h-[3px] w-[120px] bg-pri flex-1 '>

        </div>

        <div className='p-[2px] w-8 h-8 rounded-full border-2 border-pri font-bold text-pri  flex justify-center items-center'>
          2
        </div>
      </div>


      <div className='my-4'>
        {image
          ? <img src={URL.createObjectURL(image)} alt='profile' className=' rounded-full h-32 w-32 object-cover'></img>
          : <img src='./add_profile_image.png' alt='profile' className=' rounded-full h-32 w-32'></img>
        }
      </div>

      <div class="mb-3">
        <input onChange={handleImageChange}
          class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
          type="file"
          id="formFile" />
      </div>

      <div className='flex flex-col w-full px-2 gap-4 mt-10'>

        <TitleText title='Bussines Details'></TitleText>

        <Form2ColLayout>
          <FormInput label="User Name(Bussiness Name)" onChange={handleNameChange} value={name}></FormInput>
          <FormInput label="Alt Email" type='email' onChange={handleAltEmailChange} value={altEmail} required={false}></FormInput>
        </Form2ColLayout>

        <Form2ColLayout>
          <FormInput type='phone' onChange={handlePhoneChange} value={phone} required={true} label="Phone"></FormInput>
          <FormInput type='phone' label="Alt Phone" onChange={handleAltPhoneChange} value={altPhone} required={false}></FormInput>
        </Form2ColLayout>

        <TitleText title='Location and Address'></TitleText>

        <Form2ColLayout>
          <FormInput label="City" onChange={handleCityChange} value={city}></FormInput>
          <FormInput label="Town" onChange={(e) => { setTown(e.target.value) }} value={town} ></FormInput>
        </Form2ColLayout>

        <Form2ColLayout>
          <FormInput label="Area" onChange={(e) => setArea(e.target.value)} value={area} required={false}></FormInput>
          <FormInput label="Street" onChange={(e) => setStreet(e.target.value)} value={street}></FormInput>
        </Form2ColLayout>

      </div>
      <TitleText title='About'></TitleText>

      <WysiwygEditor></WysiwygEditor>

      <LoadingButton type={"submit"} loading={loading} text={"next"}/>
{/* 
      <button
        type="Submit"
        data-te-ripple-init
        data-te-ripple-color="light"
        className="mt-20 inline-block rounded bg-pri px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
        Next
      </button> */}
    </div>

    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  </form>
);
}

AddProfilePage.propTypes = {};

AddProfilePage.defaultProps = {};

export default AddProfilePage;
