import React from 'react';
import PropTypes from 'prop-types';
import './MobileNavLink.css';
import { NavLink } from 'react-router-dom';

const MobileNavLink = ({path,text}) => (
  <NavLink to={path} className={({ isActive }) => (isActive ? ' text-white text-xl p-2 font-bold bg-pri' : 'text-xl text-default-color mx-4 w-full')}>
  {text}
</NavLink>
);

MobileNavLink.propTypes = {};

MobileNavLink.defaultProps = {};

export default MobileNavLink;
