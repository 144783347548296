import React from 'react';
import PropTypes from 'prop-types';
import './Notifications.css';
import NotificationStrip from '../NotificationStrip/NotificationStrip';
import EmptyState from '../EmptyState/EmptyState';

const Notifications = () => (
  <div className='Notifications flex flex-col gap-2  divide-y-2'>
  
            {/* <NotificationStrip></NotificationStrip>

            <NotificationStrip></NotificationStrip> */}
            <EmptyState message='No notifications found'></EmptyState>

          </div>
);

Notifications.propTypes = {};

Notifications.defaultProps = {};

export default Notifications;
