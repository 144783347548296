import React from 'react';
import PropTypes from 'prop-types';
import './NotificationStrip.css';
import TitleText from '../TitleText/TitleText';

const NotificationStrip = () => (
  <div className="NotificationStrip" data-testid="NotificationStrip">
    <div className='flex items-center gap-4 justify-center'>

      <div className='flex justify-center items-center self-center rounded-full bg-pri w-16 h-16'>
        <p className='text-3xl font-bold text-white'>M</p>
      </div>

      <div className='NotificationContent flex flex-1 flex-col'>
        <div className=' flex justify-between'>
          <TitleText title="Notification title"></TitleText>

          <div className='flex gap-2 items-center justify-center'>
            <p className=' py px-2 rounded-full text-white max-h-max  bg-pri'>New</p>
            <div className='Cross'>
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.356 6.50293L6.35596 18.5029" stroke="black" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.35596 6.50293L18.356 18.5029" stroke="black" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

            </div>
          </div>
        </div>

        <p className=' text-default-color'>Id odio commodo et metus donec consequat mattis volutpat. Eget tellus suscipit cras id diam. Interdum.</p>

        <div className='flex justify-end'>
          <p className='text-xs text-default-color'>14/03/2012  5:00 PM</p>
        </div>

      </div>
    </div>

  </div>
);

NotificationStrip.propTypes = {};

NotificationStrip.defaultProps = {};

export default NotificationStrip;
