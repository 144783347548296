import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './PropertyModal.css';
import { Modal, initTE, ripple } from "tw-elements";
import TitleText from '../TitleText/TitleText';
import WysiwygEditor from '../WYSIWYGEditor/WYSIWYGEditor';
import FormInput from '../FormInput/FormInput';
import AddImages from '../AddImages/AddImages';
import { EditorState } from 'draft-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormSelect from '../FormSelect/FormSelect';
import { json, useNavigate } from 'react-router-dom';
import Form2ColLayout from '../../layout/Form_2_ColLayout/Form_2_ColLayout';
import { addProperty } from '../../network/api';
import LoadingButton from '../LoadingButton/LoadingButton';
import RequiredMarker from '../RequiredMarker/RequiredMarker';


const PropertyModal = ({ onSave }) => {

  // const navigate = useNavigate()



  const roomsOptions = [{ 0: "Select Value" }, { 1: "One" }, { 2: "Two" }, { 3: "Three" }, { 4: "Four" }, { 5: "5 or More" }]

  const listingType = [{ 0: "Select Value" }, { 1: "Sale" }, { 2: "Rent" }]


  const categoryOptions = [{"Appartments/flats":"Appartments/flats"},{"Stand Alone":"Stand Alone"},{"Land":"Land"},{"Office":"Office"},{"Shops":"Shops"},{"Godowns":"Godowns"},{"Conference Hall":"Conference Hall"},{"Gated Community":"Gated Community"},{"Affordable Housing":"Affordable Housing"},{"Hostels":"Hostels"},{"Servised Appartments":"Servised Appartments"}] 

  const $ = require("jquery")

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());




  const [price, setPrice] = useState()
  const [title, setTitle] = useState()
  const [rooms, setRooms] = useState()
  const [bathrooms, setBathrooms] = useState()
  const [location, setLocation] = useState()
  const [type, setType] = useState()
  const [size, setSize] = useState()
  const [category, setCategory] = useState()
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [mainImage,setMainImage] = useState()
  // const [myModal,setMyModal] = useState()



  // useEffect(() => {
  initTE({ Modal });

  const myModal = (new Modal(document.getElementById("myModal")));
  const dialog = (new Modal(document.getElementById("Dialog")));

  const [published, setPublished] = useState(false)


  const handlePublishChange = () => {
    setPublished(!published)
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleRoomsChange = (e) => {
    setRooms(e.target.value)
  }

  const handleBathroomsChange = (e) => {
    setBathrooms(e.target.value)
  }

  const handleLocationChange = (e) => {
    setLocation(e.target.value)
  }

  const handlePriceChange = (e) => {
    setPrice(e.target.value)
  }

  const handleTypeChange = (e) => {
    setType(e.target.value)
  }

  const handleSizeChange = (e) => {
    setSize(e.target.value)
  }

  const handleCategoryChange = (e) => {
    setCategory(e.target.value)
  }

  const onEditorStateChange = (e) => {
    setEditorState(e)
  }



  const selectedImages = (images) => {
    setImages(images)


  }

  const selectMainImage = (images) =>{
    setMainImage(images[0])
  }


  const handleCreateProperty = (e) => {
    e.preventDefault()

    if (images.length <= 0) {

      toast.error('Kindly Add atleast one Property image to continue', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return
    }


    const formData = new FormData();

    formData.append("title", title)
    formData.append("rooms", rooms)
    formData.append("bathrooms", bathrooms)
    formData.append("location", location)
    formData.append("category", "All")
    formData.append("size", 20)
    formData.append("price", price)
    formData.append("desc", editorState)
    formData.append("published", published)
    formData.append("category",category)
    formData.append("property_type", type)
    formData.append("main_image",mainImage)


    if (images.length <= 0) {
      toast.error('Kindly Add atleast one Property image to continue', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return
    }

    if (!mainImage) {
      toast.error('Main image is required to continue', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return
    }



    for (let i = 0; i < images.length; i++) {

      formData.append('images[]', images[i]);
    }

    console.log(formData.get("images"))






    // var formData = new FormData($(this)[0]);
    // $.ajax({
    //   url: 'http://localhost:3002/api/properties',
    //   type: 'POST',
    //   data: formData,
    //   processData: false,
    //   contentType: false,
    //   success: function (response) {
    //     console.log(response);

    //     myModal.hide()

    //     toast.success('Property added Succesfully ', {
    //       position: "bottom-center",
    //       autoClose: 3000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     })

    //     onSave();



    //   },
    //   error: function (xhr, status, error) {
    //     console.log(xhr.responseText);

    //     toast.error(error, {
    //       position: "bottom-center",
    //       autoClose: 3000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     });
    //   }
    // });

    // alert(JSON.stringify(formData.get("images")))

    setLoading(true)
    addProperty(formData)
      .then(() => {

        // myModal.getInstance().hide()

        toast.success('Property added Successfully ', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })

        onSave();
      })
      .catch((error) => {
        toast.error(error, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setLoading(false)
      })



  }



  return (

    <div>

      <div className='flex w-full justify-between px-6'>

        <TitleText title='All Properties'></TitleText>


        <button
          type="button"
          className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white  transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-toggle="modal"
          data-te-target="#myModal"
          data-te-ripple-init
          data-te-ripple-color="light">
          Add Property
        </button>
      </div>


      <div
        data-te-modal-init
        class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="myModal"
        tabindex="-1"

        aria-labelledby="exampleModalLgLabel"
        aria-modal="true"
        role="dialog">
        <div
          data-te-modal-dialog-ref
          class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]">
          <div
            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div
              class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
              <div className='flex items-baseline gap-2'>
              <h5
                class="text-xl  leading-normal text-pri dark:text-neutral-200 font-bold"
                id="exampleModalLgLabel">
                Add Property
              </h5>
              <p className='text-gray-400 text-xs '>(items marked with <span className='text-red-500'>*</span> are Required)</p>
              </div>
              <button
                type="button"
                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>


            <div class="relative p-4">
              {/* <div className='upload min-h-[200px] bg-gray-300 rounded-lg'> */}
              <TitleText title='Main image'></TitleText>
              <AddImages setImages={selectMainImage} multiple={false} ></AddImages>
              <TitleText title='Other images(Multiple)'></TitleText>
              <AddImages setImages={selectedImages}></AddImages>

              {/* </div> */}
              <form onSubmit={handleCreateProperty}>
                <div className='flex flex-col gap-2'>
                  <TitleText title='Property Details'></TitleText>
                </div>

                <div className='body flex flex-col gap-6 py-4'>

                  <FormInput label="Property Title" onChange={handleTitleChange} required={true} value={title}></FormInput>

                  <Form2ColLayout>
                    <FormSelect name="rooms" label="Rooms" options={roomsOptions} type='number' onChange={handleRoomsChange} value={rooms}></FormSelect>
                    <FormSelect name="bathrooms" label="Bathrooms" options={roomsOptions} type='number' onChange={handleBathroomsChange} value={bathrooms}></FormSelect>
                  </Form2ColLayout>

                  <Form2ColLayout>
                    <FormInput name="price" label="Price" onChange={handlePriceChange} required={true} value={price} ></FormInput>

                    <FormInput name="location" label="Location" onChange={handleLocationChange} required={true} value={location} ></FormInput>
                  </Form2ColLayout>


                  <Form2ColLayout>
                    <FormSelect name="type" label="Listing Type" options={listingType} required={true} onChange={handleTypeChange} value={type}></FormSelect>
                    <FormInput name="size" label="Size in SQ ft" onChange={handleSizeChange} value={size} type='number'></FormInput>
                  </Form2ColLayout>

                  <FormSelect label="Category" options={categoryOptions} onChange={handleCategoryChange} value={category}></FormSelect>


                  <label className='flex gap-2'>
                    <input type="checkbox" checked={published} onChange={handlePublishChange}></input>
                    published
                  </label>

                  <TitleText title='Properties Description' ></TitleText>


                  <WysiwygEditor onchange={onEditorStateChange} value={editorState}></WysiwygEditor>

                </div>
                <div className=' w-full flex justify-center items-center'>
                  <LoadingButton type="submit" text="save" loading={loading}></LoadingButton>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

    </div >

  );
}

PropertyModal.propTypes = {};

PropertyModal.defaultProps = {};

export default PropertyModal;
