import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import './DashboardLayout2.css';
import { Children } from 'react';
import { NavLink, redirect, useNavigate, Link, json } from 'react-router-dom';
import SidenavNavStrip from '../../components/SidenavNavStrip/SidenavNavStrip';
import { ToastContainer } from 'react-toastify';
import { Dialog } from '@mui/material';
import { AuthContext } from '../../providers/AuthProvider';
import MobileNav from '../../components/MobileNav/MobileNav';
import { getUserProfile } from '../../network/api';


const DashboardLayout2 = ({ children }) => {

  const navigate = useNavigate()

  const { logOut, validateToken, user } = useContext(AuthContext)

  const [profile,setProfile] = useState()

  useEffect(() => {

    const check = async () => {

      if (!user){

        try {
          await validateToken()

        } catch (err) {
          navigate("/")
        }
      }else{
        console.log(user)
      }
    }

    check()

  }, [navigate])

  useEffect(()=>{
    getUserProfile()
          .then((response)=>{
            setProfile(response.data)
          })
  },[])

  const logout = () => {
    logOut()
  }



  return (
    <div className="DashBoardPage flex w-full gap-4 min-h-screen" data-testid="DashBoardPage">
      <div className='Left-Nav hidden lg:flex w-2/12 flex-col gap-4 min-w-[300px] relative '>

        <div className='fixed top-0  bg-pri left-0 min-w-[300px] h-full'>
          <div className='flex flex-col justify-between h-full'>

            <div className='flex flex-col gap-1 invisile' >
              <div className='min-h-[280px]'>

              </div>
              <SidenavNavStrip path="/dashboard" text="Dashboard" />
              <SidenavNavStrip path="/properties" text="My Properties" />
              <SidenavNavStrip path="/Classifieds" text="My Classifieds" />
              <SidenavNavStrip path="/markets" text="Market" />
              <SidenavNavStrip path="/profile" text="Profile" />

            </div>
            <button onClick={logout} className='logout   bg-sec flex p-4 justify-center justify-self-end items-center text-white w-full'>
              Logout
            
            </button>
          </div>




        </div>

      </div>

      <div className='content px-6 py-8 flex flex-col gap-4 w-full md:px-12   lg:w-9/12 mx-auto'>
        <div className='lg:hidden flex w-full justify-end'>
          <MobileNav></MobileNav>
        </div>
        {/* nav */}
        {/* <div className='nav flex justify-between items-center lg:mt-20 mt-4'>
          <div className='flex gap-2'>
            <NavLink to="/dashboard">
              <img className='Logo' src='/logo.png' alt='maskani logo'></img>
            </NavLink>
          </div>
          <div className='Nav '>

            <div className='flex gap-2 justify-center  items-center '>
              <Link to='/dashboard'>
                <div >
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.8633 8.01123C18.8633 6.41993 18.2311 4.89381 17.1059 3.76859C15.9807 2.64337 14.4546 2.01123 12.8633 2.01123C11.272 2.01123 9.74586 2.64337 8.62064 3.76859C7.49542 4.89381 6.86328 6.41993 6.86328 8.01123C6.86328 15.0112 3.86328 17.0112 3.86328 17.0112H21.8633C21.8633 17.0112 18.8633 15.0112 18.8633 8.01123Z" stroke="black" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.5928 21.0112C14.417 21.3143 14.1647 21.5659 13.861 21.7408C13.5574 21.9156 13.2132 22.0077 12.8628 22.0077C12.5124 22.0077 12.1682 21.9156 11.8646 21.7408C11.561 21.5659 11.3086 21.3143 11.1328 21.0112" stroke="black" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>


                </div>
              </Link>

              <div>

                <Link to='/profile'>
                  { profile &&
                     <img src={profile.image} className='w-14 h-14 rounded-full' alt='Profile Icon' ></img>
                  }
                </Link>

              </div>

            </div>
            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 10H7" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M21 6H3" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M21 14H3" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M21 18H7" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg> */}

          {/* </div> */}
        {/* </div> */} 

        {/* content */}
        <div className='flex justify-between mt-6 px-'>
          {/* <div className='Greetings flex flex-col gap-2 pl-2 '>
            <p className=' text-xl text-sec font-bold'>😄 Hi There</p>
            <p className=' text-default-color text-lg'>Welcome to Maskani </p>
          </div> */}



          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

        </div>
        {true
          ? children
          : <>loading</>

        }

      </div>

    </div>
  );
}

DashboardLayout2.propTypes = {};

DashboardLayout2.defaultProps = {};

export default DashboardLayout2;
