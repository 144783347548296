import React from 'react';
import PropTypes from 'prop-types';
import './DashBoardStatCard.css';

const DashBoardStatCard = ({label="label",value="000",color="#2F80ED"}) => (
  <div className="DashBoardStatCard rounded bg-[#2F80ED] text-white flex flex-col gap-2 p-2 items-center" data-testid="DashBoardStatCard">
    <p className=' self-end' >{label}</p>
    <p className=' text-5xl font-bold mb-8 se'>{value}</p>
  </div>
);

DashBoardStatCard.propTypes = {};

DashBoardStatCard.defaultProps = {};

export default DashBoardStatCard;
