import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './PropertiesPage.css';
import 'tw-elements'
import DashboardLayout from '../../layout/DashboardLayout/DashboardLayout';
import MUIDataTable from "mui-datatables";
import ViewCounter from '../../components/ViewCounter/ViewCounter';
import PublishedStatus from '../../components/PublishedStatus/PublishedStatus';
import TableActions from '../../components/TableActions/TableActions';
import PropertyModal from '../../components/PropertyModal/PropertyModal';
import { Delete } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, initTE, ripple } from "tw-elements";
import Dialog from '../../components/Dialog/Dialog';
import Loader from '../../components/Loader/Loader';
import { BASE_URL } from '../../utils/constants';
import { deleteProperty, getAllProperties } from '../../network/api';
import EmptyState from '../../components/EmptyState/EmptyState';
import TitleText from '../../components/TitleText/TitleText';

const PropertiesPage = () => {

  const [data, setData] = useState(null);
  const [columns, setColums] = useState();
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  initTE({ Modal });

  const dialog = (new Modal(document.getElementById("dialog")));

  // 

  const del = (value) => {
    // dialog.show()
    alert("Are you sure you want to delete this record")

    deleteProperty(value)
      .then(() => {
        toast.success('Property Deleted Succesfully')
        setData(null)
        setReload(true)
      })
      .catch((error) => {
        console.log('Delete request failed');
        console.log(error);
        toast.error(error)
      })
  }

  const edit = (value) => {
    navigate("edit/" + value)
  }



  useEffect(() => {
    setLoading(true)
    getAllProperties()
      .then((response) => {

        console.log(response.data)

        setData(response.data)
        setReload(false)
        setColums([
          {
            name: "image_urls",
            label: "Photo",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div className='w-11 h-11 flex justify-center items-center'>
                    {value &&
                      <img src={value[0]} className=' w-full h-full rounded-lg' />
                    }
                  </div>
                );
              }
            },
          },
          {
            name: "title",
            label: "Title",
            options: {
              sort: true,
            },
          },
          {
            name: "location",
            label: "Location",
            options: {
              sort: true,
            },
          },
          {
            name: "views",
            label: "Views",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <ViewCounter value={value}></ViewCounter>;
              }
            },
          },
          {
            name: "published",
            label: "published",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (<PublishedStatus value={value}></PublishedStatus>)
              }
            },
          },
          {
            name: "id",
            label: "Actions",
            options: {
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (<TableActions ed={edit} del={del} value={value}></TableActions>)
              }
            },
          }
        ])

      })
      .catch((error) => {
        console.log(error.response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [reload])





  const options = {
    tableRoot: {
      boxShadow: "none",
    },
    filterType: 'checkbox',
    elevation: 0,
    border: 'none',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRows: false 

  };




  return (
    <DashboardLayout>

      {/* <PropertyModal onSave={() => { setReload(true); setData(null) }}></PropertyModal> */}
      <div className='flex w-full justify-between px-6'>

        <TitleText title='All Properties'></TitleText>

        <Link to="add">
        <button
          type="button"
          className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white  transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init
          data-te-ripple-color="light">
          Add Property
        </button>
        </Link>

      </div>
      {/* <Dialog></Dialog> */}

      <div className='h-full w-full justify-center items-center text-default-color p-6'>

        {loading ?
          <Loader></Loader>
          : data == null ?
            <EmptyState></EmptyState>
            :
            <MUIDataTable
              data={data}
              elevation={0}
              columns={columns}
              options={options}
            />
        }
      </div>
    </DashboardLayout>
  );
}
PropertiesPage.propTypes = {};

PropertiesPage.defaultProps = {};

export default PropertiesPage;
