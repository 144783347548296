import React from 'react';
import PropTypes from 'prop-types';
import './EmptyState.css';

const EmptyState = ({ message = "No data found" }) => {





  return (
    <div className="EmptyState flex flex-col w-full h-full justify-center items-center gap-4 border rounded" data-testid="EmptyState">
      <div>
        <svg width="275" height="186" viewBox="0 0 275 186" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_205_969)">
            <path d="M250.746 65.2878C250.458 65.5242 250.171 65.7503 249.876 65.973H177.197C176.655 65.7503 176.116 65.5219 175.58 65.2878C167.907 61.9487 160.765 57.5024 154.383 52.0908C150.145 48.5004 146.246 44.7934 143.42 41.3195C139.477 36.4717 135.845 31.1888 134.156 25.1693C132.47 19.1498 132.974 12.2499 136.739 7.25817C137.905 5.72265 139.338 4.41004 140.97 3.38336C141.241 3.21206 141.515 3.04761 141.796 2.89344C146.311 0.358199 152.009 -0.258485 157.093 1.05367C164.373 2.93455 170.423 8.18661 174.74 14.3466C179.057 20.5031 181.86 27.5744 184.621 34.5668C187.423 22.0859 195.91 10.9069 207.373 5.22312C218.833 -0.46062 233.071 -0.392099 244.288 5.76443C247.817 7.71327 251.005 10.2243 253.727 13.1989C253.997 13.4901 254.261 13.7881 254.522 14.0896C259.993 20.4243 263.416 28.5371 263.696 36.9034C264.056 47.6816 259.061 58.5077 250.746 65.2878Z" fill="#F2F2F2" />
            <path d="M180.102 65.9728H179.075C179.061 65.7433 179.044 65.5172 179.023 65.2876C178.664 60.8063 177.912 56.3651 176.776 52.0153C174.169 42.0846 169.716 32.7333 163.651 24.4497C157.602 16.1395 149.977 9.10119 141.21 3.73604C141.146 3.6993 141.091 3.64842 141.05 3.58728C141.008 3.52613 140.981 3.45633 140.971 3.38317C140.954 3.28749 140.965 3.18903 141.002 3.09924C141.039 3.00944 141.1 2.93203 141.18 2.87602C141.259 2.82001 141.353 2.7877 141.45 2.78286C141.547 2.77803 141.643 2.80086 141.728 2.84871C141.751 2.86234 141.774 2.8772 141.796 2.89324C146.763 5.92065 151.374 9.49703 155.541 13.555C162.996 20.8271 169.007 29.4444 173.257 38.9519C177.007 47.2788 179.303 56.1859 180.048 65.2876C180.068 65.5171 180.085 65.7467 180.102 65.9728Z" fill="white" />
            <path d="M254.521 14.0887C254.513 14.0925 254.505 14.0949 254.497 14.0955C253.747 14.2326 252.993 14.3765 252.246 14.5306C246.115 15.7897 240.114 17.6121 234.318 19.9746C228.537 22.3337 222.977 25.1999 217.702 28.5396C212.42 31.8775 207.439 35.6696 202.816 39.8728C198.192 44.0699 193.951 48.6697 190.143 53.6179C187.296 57.3253 184.708 61.2242 182.397 65.2869C182.267 65.513 182.14 65.7425 182.013 65.9721H180.838C180.961 65.7425 181.088 65.513 181.218 65.2869C184.014 60.3089 187.221 55.5726 190.804 51.1272C194.767 46.2075 199.159 41.65 203.929 37.5088C208.669 33.391 213.756 29.6908 219.134 26.4497C224.534 23.1955 230.211 20.4256 236.099 18.1725C241.813 15.988 247.713 14.3231 253.726 13.1979C253.894 13.1671 254.058 13.1363 254.226 13.1054C254.867 12.9889 255.141 13.9551 254.521 14.0887Z" fill="white" />
            <path d="M67.7242 68.0091C69.5519 64.5589 69.8536 60.0652 67.581 56.8903C66.2282 55.0005 64.1159 53.7599 61.8996 53.0601C59.6833 52.3604 57.3452 52.1547 55.0273 51.9847C52.7328 51.8164 50.3974 51.6816 48.156 52.2006C45.9147 52.7195 43.7436 53.9873 42.6517 56.0124C40.8071 59.4334 42.4874 63.8201 41.1326 67.4629C39.8893 70.8059 36.3509 72.7604 32.8724 73.5491C29.394 74.3378 25.7634 74.2539 22.2833 75.0349C18.8031 75.816 15.2587 77.7523 13.9934 81.0871C13.0784 83.4985 13.533 86.2762 14.7387 88.5563C15.9444 90.8363 17.8327 92.6822 19.837 94.3055C22.8272 96.7271 26.3254 98.8011 30.1674 99.011C34.2157 99.2322 38.174 97.3051 41.0878 94.486C44.0015 91.6668 45.9852 88.0237 47.57 84.292C48.5754 81.9246 49.5253 79.3769 51.5402 77.7782C53.0207 76.6035 54.9037 76.0889 56.7299 75.6021L58.7978 75.051C60.6716 74.5607 62.4252 73.6922 63.9509 72.4989C65.4765 71.3057 66.7419 69.8128 67.6691 68.1123C67.6876 68.078 67.7059 68.0436 67.7242 68.0091Z" fill="#2F2E41" />
            <path d="M51.4171 181.504H47.2171L45.2188 165.303H51.4181L51.4171 181.504Z" fill="#FFB8B8" />
            <path d="M44.2155 180.305H52.3159V185.405H39.1152C39.1152 184.735 39.2472 184.072 39.5035 183.453C39.7598 182.834 40.1355 182.272 40.6091 181.799C41.0827 181.325 41.6449 180.949 42.2637 180.693C42.8825 180.437 43.5457 180.305 44.2155 180.305Z" fill="#2F2E41" />
            <path d="M86.3624 181.504H82.1625L80.1641 165.303H86.3634L86.3624 181.504Z" fill="#FFB8B8" />
            <path d="M79.1618 180.305H87.2622V185.405H74.0615C74.0615 184.735 74.1934 184.072 74.4498 183.453C74.7061 182.834 75.0817 182.272 75.5553 181.799C76.029 181.325 76.5912 180.949 77.21 180.693C77.8288 180.437 78.492 180.305 79.1618 180.305Z" fill="#2F2E41" />
            <path d="M59.4913 168.628C59.4327 168.628 59.3741 168.625 59.3158 168.619L42.2278 166.788C42.0009 166.763 41.7817 166.691 41.5849 166.575C41.3881 166.46 41.2182 166.304 41.0865 166.117C40.9548 165.931 40.8643 165.718 40.8211 165.494C40.7779 165.27 40.7829 165.039 40.8358 164.817L55.9658 107.271C56.0367 106.978 56.188 106.711 56.4026 106.499C56.6172 106.288 56.8866 106.141 57.1805 106.074L71.2161 102.162C71.4976 102.1 71.7904 102.114 72.0644 102.204C72.3383 102.293 72.5835 102.454 72.7745 102.669C81.7987 112.848 88.5993 135.412 93.7509 163.853C93.7897 164.067 93.7845 164.288 93.7355 164.5C93.6865 164.713 93.5948 164.913 93.4659 165.089C93.337 165.265 93.1736 165.413 92.9857 165.524C92.7978 165.634 92.5893 165.706 92.373 165.733L78.3717 167.521C77.9746 167.573 77.5725 167.474 77.2446 167.244C76.9168 167.014 76.6869 166.67 76.6002 166.279L69.788 135.908C69.7254 135.628 69.5686 135.379 69.3439 135.201C69.1193 135.023 68.8404 134.928 68.5539 134.931C68.2675 134.935 67.9909 135.036 67.7704 135.219C67.5499 135.402 67.3989 135.655 67.3427 135.936L61.0628 167.336C60.9917 167.7 60.7959 168.028 60.5092 168.264C60.2224 168.5 59.8625 168.629 59.4913 168.628Z" fill="#2F2E41" />
            <path d="M56.8912 75.9103C61.5384 75.9103 65.3058 72.1429 65.3058 67.4957C65.3058 62.8484 61.5384 59.0811 56.8912 59.0811C52.2439 59.0811 48.4766 62.8484 48.4766 67.4957C48.4766 72.1429 52.2439 75.9103 56.8912 75.9103Z" fill="#FFB8B8" />
            <path d="M56.6559 107.431C56.3022 107.431 55.9584 107.314 55.6783 107.098C55.3981 106.882 55.1972 106.579 55.1069 106.237L50.0447 87.2198C49.6793 85.8533 49.8306 84.4 50.4697 83.138C51.1087 81.8761 52.1906 80.8941 53.5084 80.3799C57.5833 78.7893 61.4656 78.1992 65.0473 78.6254C68.2307 79.0048 72.1578 97.8515 71.898 98.2536L73.1543 102.022C73.2255 102.236 73.251 102.462 73.2291 102.686C73.2073 102.911 73.1385 103.128 73.0273 103.324C72.9161 103.52 72.765 103.69 72.5837 103.824C72.4024 103.957 72.195 104.052 71.975 104.1L57.0051 107.393C56.8904 107.419 56.7733 107.431 56.6559 107.431Z" fill="#CCCCCC" />
            <path d="M61.1932 126.881C61.2653 126.507 61.2545 126.121 61.1614 125.752C61.0684 125.382 60.8955 125.037 60.6548 124.742C60.4142 124.446 60.1116 124.207 59.7685 124.041C59.4254 123.875 59.05 123.786 58.6689 123.781L55.7524 114.986L52.7715 118.767L55.9981 126.539C56.0317 127.18 56.2992 127.786 56.75 128.243C57.2007 128.7 57.8034 128.976 58.4438 129.018C59.0841 129.06 59.7177 128.866 60.2244 128.472C60.7312 128.078 61.0759 127.512 61.1932 126.881Z" fill="#FFB8B8" />
            <path d="M98.6908 105.744C98.5162 105.405 98.2694 105.108 97.9679 104.875C97.6663 104.642 97.3173 104.478 96.9454 104.394C96.5736 104.311 96.1879 104.31 95.8155 104.391C95.4432 104.473 95.0932 104.635 94.7903 104.866L87.0627 99.7539L87.0557 104.569L94.395 108.685C94.8174 109.168 95.4024 109.48 96.0391 109.56C96.6758 109.641 97.3199 109.485 97.8494 109.123C98.3789 108.76 98.7571 108.216 98.9121 107.593C99.0672 106.97 98.9885 106.312 98.6908 105.744Z" fill="#FFB8B8" />
            <path d="M55.0791 122.817C54.7412 122.817 54.4118 122.711 54.1378 122.513C53.8639 122.315 53.6593 122.036 53.5532 121.715L46.716 107.251C46.4356 106.404 46.2928 105.517 46.293 104.624L48.3486 85.8251C48.3485 84.7726 48.7501 83.7597 49.4715 82.9932C50.1929 82.2268 51.1796 81.7646 52.2302 81.7009C53.2808 81.6373 54.3161 81.977 55.1247 82.6508C55.9333 83.3246 56.4543 84.2816 56.5812 85.3264L53.4363 106.784L58.4549 119.953C58.5343 120.304 58.4938 120.671 58.3398 120.995C58.1859 121.32 57.9274 121.583 57.6058 121.743L55.7976 122.648C55.5745 122.759 55.3285 122.817 55.0791 122.817Z" fill="#CCCCCC" />
            <path d="M91.5944 107.643C91.3427 107.643 91.0945 107.584 90.8698 107.47L77.2728 100.602C76.4757 100.2 75.7482 99.6726 75.1182 99.0399L61.8469 85.7256C61.1039 84.9802 60.6734 83.9793 60.6433 82.9272C60.6132 81.8752 60.9858 80.8513 61.685 80.0646C62.3842 79.2779 63.3572 78.7877 64.4055 78.6941C65.4539 78.6005 66.4984 78.9106 67.3259 79.5609L81.7023 95.527L93.0982 102.763C93.4018 102.955 93.6321 103.243 93.7522 103.582C93.8723 103.92 93.8752 104.289 93.7604 104.63L93.118 106.547C93.0109 106.866 92.8062 107.144 92.5328 107.34C92.2594 107.537 91.9312 107.643 91.5944 107.643Z" fill="#CCCCCC" />
            <path d="M47.3203 63.1455C48.7497 65.0154 50.8043 66.3083 53.1086 66.7878C55.4442 67.2705 57.875 66.9222 59.9812 65.8031C61.6759 64.8875 63.032 63.5132 64.3603 62.1602C64.9146 61.5955 65.5051 60.9443 65.4772 60.1739C65.434 58.9804 63.9991 58.3279 62.7677 58.0676C58.7254 57.2244 54.5194 57.6974 50.7655 59.4174C49.0936 60.194 47.3516 61.599 47.6027 63.3456" fill="#2F2E41" />
            <path d="M268.297 65.2871H103.164C101.62 65.2893 100.14 65.9036 99.0481 66.9954C97.9564 68.0872 97.342 69.5673 97.3398 71.1113V134.835C97.342 136.379 97.9564 137.859 99.0481 138.951C100.14 140.043 101.62 140.657 103.164 140.659H268.297C269.841 140.657 271.322 140.043 272.413 138.951C273.505 137.859 274.119 136.379 274.122 134.835V71.1113C274.119 69.5673 273.505 68.0872 272.413 66.9954C271.322 65.9036 269.841 65.2893 268.297 65.2871ZM273.436 134.835C273.435 136.197 272.893 137.504 271.929 138.467C270.966 139.43 269.66 139.972 268.297 139.974H103.164C101.802 139.972 100.495 139.43 99.5321 138.467C98.5687 137.504 98.0267 136.197 98.025 134.835V71.1113C98.0267 69.7489 98.5687 68.4427 99.5321 67.4793C100.495 66.5159 101.802 65.974 103.164 65.9723H268.297C269.66 65.974 270.966 66.5159 271.929 67.4793C272.893 68.4427 273.435 69.7489 273.436 71.1113V134.835Z" fill="#3F3D56" />
            <path d="M133.147 122.845C129.217 122.845 125.375 121.68 122.108 119.496C118.84 117.313 116.293 114.209 114.789 110.579C113.285 106.948 112.891 102.952 113.658 99.0977C114.425 95.2432 116.317 91.7025 119.096 88.9236C121.875 86.1446 125.416 84.2521 129.271 83.4853C133.125 82.7186 137.121 83.1121 140.751 84.6161C144.382 86.1201 147.486 88.667 149.669 91.9347C151.853 95.2024 153.018 99.0443 153.018 102.974C153.012 108.243 150.917 113.293 147.191 117.019C143.466 120.744 138.415 122.839 133.147 122.845ZM133.147 83.7887C129.353 83.7887 125.643 84.9139 122.488 87.0221C119.333 89.1302 116.874 92.1266 115.422 95.6323C113.97 99.138 113.59 102.996 114.33 106.717C115.07 110.439 116.898 113.857 119.581 116.541C122.264 119.224 125.683 121.051 129.404 121.791C133.126 122.532 136.983 122.152 140.489 120.7C143.995 119.247 146.991 116.788 149.099 113.633C151.208 110.478 152.333 106.769 152.333 102.974C152.327 97.8878 150.304 93.0112 146.707 89.4144C143.11 85.8177 138.234 83.7945 133.147 83.7887Z" fill="#3F3D56" />
            <path d="M253.561 90.2969H174.42C173.314 90.2975 172.251 90.7263 171.454 91.4933C170.657 92.2604 170.188 93.3061 170.145 94.4115C170.139 94.4673 170.137 94.5233 170.138 94.5794C170.14 95.7147 170.591 96.803 171.394 97.6058C172.197 98.4086 173.285 98.8603 174.42 98.8619H253.561C254.697 98.8619 255.786 98.4107 256.589 97.6076C257.392 96.8044 257.844 95.7152 257.844 94.5794C257.844 93.4436 257.392 92.3543 256.589 91.5512C255.786 90.7481 254.697 90.2969 253.561 90.2969Z" fill="#6C63FF" />
            <path d="M253.561 107.084H174.42C173.314 107.085 172.251 107.513 171.454 108.28C170.657 109.047 170.188 110.093 170.145 111.199C170.139 111.254 170.137 111.31 170.138 111.366C170.14 112.502 170.591 113.59 171.394 114.393C172.197 115.196 173.285 115.647 174.42 115.649H253.561C254.697 115.649 255.786 115.198 256.589 114.395C257.392 113.592 257.844 112.502 257.844 111.366C257.844 110.231 257.392 109.141 256.589 108.338C255.786 107.535 254.697 107.084 253.561 107.084Z" fill="#6C63FF" />
            <path d="M130.93 111.655C130.376 111.656 129.836 111.477 129.392 111.144L129.365 111.124L123.574 106.69C123.305 106.484 123.08 106.228 122.911 105.935C122.742 105.643 122.632 105.32 122.588 104.985C122.543 104.65 122.565 104.309 122.653 103.983C122.74 103.657 122.891 103.351 123.096 103.082C123.302 102.814 123.558 102.589 123.85 102.42C124.143 102.251 124.466 102.141 124.801 102.097C125.136 102.052 125.476 102.074 125.803 102.161C126.129 102.249 126.435 102.399 126.703 102.605L130.454 105.481L139.319 93.9204C139.525 93.6523 139.781 93.4273 140.074 93.2583C140.366 93.0892 140.689 92.9794 141.024 92.9352C141.359 92.891 141.699 92.9132 142.026 93.0005C142.352 93.0879 142.658 93.2387 142.926 93.4443L142.871 93.5191L142.928 93.4453C143.469 93.861 143.823 94.4743 143.912 95.1506C144.001 95.8269 143.819 96.511 143.404 97.0528L132.977 110.651C132.736 110.964 132.426 111.218 132.071 111.392C131.716 111.566 131.326 111.656 130.93 111.655Z" fill="#6C63FF" />
            <path d="M130.873 185.881H0.3426C0.251737 185.881 0.164595 185.845 0.100345 185.781C0.036095 185.717 0 185.63 0 185.539C0 185.448 0.036095 185.361 0.100345 185.297C0.164595 185.232 0.251737 185.196 0.3426 185.196H130.873C130.964 185.196 131.051 185.232 131.116 185.297C131.18 185.361 131.216 185.448 131.216 185.539C131.216 185.63 131.18 185.717 131.116 185.781C131.051 185.845 130.964 185.881 130.873 185.881Z" fill="#3F3D56" />
            <path d="M234.084 49.8532C226.927 48.8687 219.659 49.0326 212.553 50.3386C205.468 51.6338 198.629 54.0251 192.281 57.4265C188.713 59.3435 185.327 61.5807 182.164 64.1104C181.647 64.5231 182.379 65.2462 182.891 64.8371C188.441 60.4223 194.662 56.9251 201.318 54.4784C207.922 52.036 214.863 50.6279 221.896 50.3038C225.876 50.121 229.864 50.3019 233.811 50.8442C233.942 50.8757 234.08 50.8561 234.198 50.7895C234.315 50.7228 234.403 50.6141 234.443 50.4853C234.478 50.3539 234.46 50.2139 234.393 50.0955C234.326 49.9772 234.215 49.8901 234.084 49.8532Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_205_969">
              <rect width="274.122" height="185.499" fill="white" transform="translate(0 0.383789)" />
            </clipPath>
          </defs>
        </svg>

      </div>

      <p className=' text-center text-default-color '>{message}</p>
    </div>
  )
};

EmptyState.propTypes = {};

EmptyState.defaultProps = {};

export default EmptyState;
