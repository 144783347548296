import React from 'react';
import PropTypes from 'prop-types';
import './Form_2_ColLayout.css';


const Form2ColLayout = ({children}) => (
  <div className='grid gap-4 w-full grid-cols-2 justify-center items-end'>
    {children}
  </div>
);

Form2ColLayout.propTypes = {};

Form2ColLayout.defaultProps = {};

export default Form2ColLayout;
