import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './AddEditClassifiedPage.css';
import { Modal, initTE, Ripple } from "tw-elements";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ToastContainer, toast } from 'react-toastify';
import { EditClassified, addClassified, getClassified } from '../../network/api';
import TitleText from '../../components/TitleText/TitleText';
import FormInput from '../../components/FormInput/FormInput';
import AddImages from '../../components/AddImages/AddImages';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import FormSelect from '../../components/FormSelect/FormSelect';
import WysiwygEditor from '../../components/WYSIWYGEditor/WYSIWYGEditor';
import Form2ColLayout from '../../layout/Form_2_ColLayout/Form_2_ColLayout';
import DashboardLayout2 from '../../layout/DashBoardLayout2/DashBoardLayout2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CurrentImages from '../../components/CurrentImages/CurrentImages';
import BackButton from '../../components/BackButton/BackButton';
import Loader from '../../components/Loader/Loader';



const AddEditClassifiedPage = () => {

  const { id } = useParams()

  const navigate = useNavigate()



  const $ = require('jquery')

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState()
  const [location, setLocation] = useState()
  const [category, setCategory] = useState()
  const [images, setImages] = useState([])
  const [published, setPublished] = useState(false)
  const [mainImage, setMainImage] = useState()
  const [shortDesc, setShortDesc] = useState()
  const [data, setData] = useState(null)
  const [mainLoader, setMainLoader] = useState(true)


  useEffect(() => {
    if (id) {
      setMainLoader(true)
      getClassified(id)
        .then((response) => {
          setData(response.data)
          // setImages(response.data.image_thumbnails_urls)
          // setMainImage(response.data.main_image_thumbnail_url)

          setTitle(response.data.title)
          setLocation(response.data.location)
          setCategory(response.data.category)
          setPublished(response.data.published)
          setShortDesc(response.data.short_desc)
          setEditorState(response.data.desc)


        })
        .catch((err) => {
          // alert(err)
        })
    }
    setMainLoader(false)
  }, [])



  const handlePublishChange = () => {
    setPublished(!published)
  }


  // initTE({ Modal });

  // const myModal = (new Modal(document.getElementById("cModal")));
  // // const dialog = (new Modal(document.getElementById("Dialog")));





  const handleTitleChange = (e) => [
    setTitle(e.target.value)
  ]

  const handleLocationChange = (e) => {
    setLocation(e.target.value)
  }


  const handleCategoryChange = (e) => {
    setCategory(e.target.value)
  }

  const handleEditorStateChange = (e) => {
    setEditorState(e)
  }



  const selectedImages = (images) => {
    setImages(images)

  }


  const selectMainImage = (images) => {
    setMainImage(images[0])

  }



  const getContent = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    return htmlContent
  }

  const options = [{ "Cleaning": "Cleaning" }, { "washing": "washing" }, { "Carwash": "Carwash" }, { "House Cleaning": "House Cleaning" }, { "washing": "washing" }, { "others": "others" }]


  const handleCreateClassified = (e) => {
    e.preventDefault()


    const formData = new FormData();

    formData.append("title", title)
    formData.append("location", location)
    formData.append("cateogry", category)
    formData.append("desc", editorState)
    formData.append("short_desc", shortDesc)



    setLoading(true)



    if (data) {

      if (images.length > 0) {

        for (let i = 0; i < images.length; i++) {
          formData.append('images[]', images[i]);
        }
      }

      if (mainImage) {

        formData.append("main_image", mainImage)

      }

      EditClassified(id, formData)
        .then(() => {

          toast.success('Classified Edited Successfully ', {
            onClose: () => {
              navigate("/classifieds")
            }
          })
          // onSave()
        })
        .catch((error) => {
          toast.error(error)
        })
        .finally(() => {
          // setLoading(false)
        })
    } else {

      if (images.length <= 0) {

        toast.error('Kindly Add atleast one Classified image to continue');

        return
      }

      if (!mainImage) {

        toast.error('Kindly Add Main image to continue');

      }

      formData.append("main_image", mainImage)

      for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i]);
      }



      addClassified(formData)
        .then(() => {

          toast.success('Classified Added Successfully', {
            onClose: () => {
              navigate('/classified')
            }
          })
          // onSave()
        })
        .catch((error) => {
          toast.error(error)
          setLoading(false)
        })


    }


  }



  return (
    <DashboardLayout2>

      {mainLoader
        ?
        <div className='flex w-full h-full justify-center items-center'>
          <Loader></Loader>
        </div>
        :

        <div>

        <div className='flex w-full justify-between px-6 mb-10'>
          <BackButton path="/classifieds"></BackButton>


          {/* <button
            type="button"
            className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white  transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            data-te-toggle="modal"
            data-te-target="#cModal"
            data-te-ripple-init
            data-te-ripple-color="light">
            Add classified
          </button> */}
        </div>


        <div
          // data-te-modal-init
          class="flex h-full w-full overflow-y-auto  outline-none"
        // id="cModal"
        // tabindex="-1"
        // aria-labelledby="myModal"
        // aria-modal="true"
        // role="dialog"
        >
          <div
            // data-te-modal-dialog-ref
            class="flex w-full   xl:mx-40 2xl:mx-60 transition-all duration-300 ease-in-out">
            <div
              class="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current  outline-none">
              <div
                class="flex  items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 w">
                <div className='flex items-baseline gap-2'>
                  <h5
                    class="text-xl font-bold leading-normal text-neutral-800 dark:text-neutral-200"
                    id="exampleModalLgLabel">
                    Add / Edit Classified
                  </h5>


                  <p className='text-gray-400 text-xs '>(items marked with <span className='text-red-500'>*</span> are Required)</p>
                </div>


              </div>


              <div class="p-4">
                <TitleText title='Main image'></TitleText>
                {data &&
                  <CurrentImages images={[data.main_image_thumbnail_url]}></CurrentImages>
                }
                <AddImages setImages={selectMainImage} multiple={false}></AddImages>
                <TitleText title='Other images(Multiple)'></TitleText>
                {data &&
                  <CurrentImages images={data.image_thumbnails_urls}></CurrentImages>
                }s
                <AddImages setImages={selectedImages}></AddImages>


                <form onSubmit={handleCreateClassified}>

                  <TitleText title='ClpreviewImageassifieds Details'></TitleText>
                  <div className='body flex flex-col gap-4'>
                    <Form2ColLayout>
                      <FormInput label="Title" required={true} onChange={handleTitleChange} value={title}></FormInput>
                      <FormInput label="Location" required={true} onChange={handleLocationChange} value={location}></FormInput>
                    </Form2ColLayout>

                    <div className='grid gap-4 w-full grid-cols-2'>
                      <FormSelect label="Category" options={options} onChange={handleCategoryChange} value={category}></FormSelect>
                    </div>

                    <FormInput label="short Desc" required={true} onChange={(e) => setShortDesc(e.target.value)} value={shortDesc}></FormInput>



                    <label className='flex gap-2'>
                      <input type="checkbox" checked={published} onChange={handlePublishChange}></input>
                      published
                    </label>



                    <WysiwygEditor onchange={handleEditorStateChange} value={editorState}>

                    </WysiwygEditor>




                  </div>

                  <div className=' w-full flex justify-center items-center'>
                    {id ?
                      <LoadingButton text={"save"} type={"submit"} loading={loading}></LoadingButton>
                      :
                      <LoadingButton text={"Edit"} type={"submit"} loading={loading}></LoadingButton>
                    }

                    {/* <button
              type="submit"
              class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
              Save
            </button> */}
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

      </div >
      }
    </DashboardLayout2>
  )
}

AddEditClassifiedPage.propTypes = {};

AddEditClassifiedPage.defaultProps = {};

export default AddEditClassifiedPage;
