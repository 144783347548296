import { axiosInstance } from "./axios"

// stats 

export const getStats = () =>{
  return axiosInstance.get("/data/stats")
}
// properties

export const getAllProperties = ()=>{
  return axiosInstance.get("/data/properties")
}

export const getProperty = (id)=>{
  return axiosInstance.get("/properties/"+id)
}

export const deleteProperty = (id) =>{
  return axiosInstance.delete(`/properties/${id}`)
}

export const addProperty = (data)=>{
  return axiosInstance.post("/properties",data,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const updateProperty = (id,data)=>{
  return axiosInstance.patch("/properties/"+id,data,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}


// classifieds

export const deleteClassified = (id) =>{
  return axiosInstance.delete(`/classifieds/${id}`)
}

export const getAllClassifieds = () =>{
  return axiosInstance.get("/data/classifieds")
}

export const getClassified = (id) =>{
  return axiosInstance.get("/classifieds/"+id)
}


export const addClassified = (data)=>{
  return axiosInstance.post("/classifieds",data,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const EditClassified = (id,data)=>{
  return axiosInstance.patch("/classifieds/"+id,data,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

// products

export const getProducts = () =>{
  return axiosInstance.get("data/products")
}

export const deleteProduct = (id) => {
  return axiosInstance.delete(`/products/${id}`)
}

export const getProduct = (id) => {
  return axiosInstance.get(`/products/${id}`)
}

export const addProduct = (data)=>{
  return axiosInstance.post("/products",data,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const editProduct = (id,data)=>{
  return axiosInstance.patch("/products/"+id,data,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}



// user profiles

export const getUserProfile = () =>{

  return axiosInstance.get("/user_profiles")
}


export const addUserProfile = (data) =>{

  return axiosInstance.post("/user_profiles",data)
}



