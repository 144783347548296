import { Password } from "@mui/icons-material";
import { createContext } from "react";
import { useContext } from "react";
import { axiosInstance } from "../network/axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../network/api";

export const AuthContext = createContext()

export const AuthProvider = ({children}) =>{
    const [user,setUser] = useState()

    const navigate = useNavigate()


    const login = async (email,pass) => {

    try {

        await axiosInstance.post("/auth/sign_in",{
            email:email,
            password:pass
        })
        .then((response)=>{
            setUser(response.data)
            getUserProfile()
            .then(()=>{
              navigate("/dashboard")
            })
            .catch(()=>{
              navigate("/add_profile")
            })
        })
        .catch((error)=>{
            alert("Wrong username or password")
        })
        
    }catch(err){

        throw(err)
    }

    
    }


    const signUp = async (email,pass) => {

        try {
    
            await axiosInstance.post("/auth",{
                email:email,
                password:pass
            })
            .then((response)=>{
                setUser(response.data)
                navigate("/add_profile")
                
            })
            .catch((error)=>{
                
            })
            
        }catch(err){
    
            throw(err)
        }
    
        
        }


    const  logOut = async ()=>{
        await axiosInstance.delete("/auth/sign_out")
        .then((response)=>{
            setUser(null)
            navigate("/")
        })
        .catch((error)=>{
            console.log(error)
        })
        setUser(null)
    }



    const validateToken = async () =>{

       await axiosInstance.get("/auth/validate_token")

       .then((response)=>{

            console.log(response.data)
            setUser(response.data)

       })
       .catch((error)=>{

            console.log(error)

            navigate("/")
            
       })
    }




    const authValues = {
        login,
        user,
        logOut,
        signUp,
        validateToken
    }

    return(

        <AuthContext.Provider value={authValues}>
            {children}
        </AuthContext.Provider>

    )
}