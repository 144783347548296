import React from 'react';
import PropTypes from 'prop-types';
import './Loader.css';
import { ColorRing, TailSpin } from 'react-loader-spinner';

const Loader = () => (
  <div className='flex w-full h-full justify-center items-center'>
  <TailSpin
    height="80"
    width="80"
    color="#2f80ed"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
  />

</div>
);

Loader.propTypes = {};

Loader.defaultProps = {};

export default Loader;
