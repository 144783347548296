import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './AddEditPropertyPage.css';
import { toast } from 'react-toastify';
import { addProperty, getAllProperties, getProperty, updateProperty } from '../../network/api';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import WysiwygEditor from '../../components/WYSIWYGEditor/WYSIWYGEditor';
import TitleText from '../../components/TitleText/TitleText';
import FormSelect from '../../components/FormSelect/FormSelect';
import FormInput from '../../components/FormInput/FormInput';
import Form2ColLayout from '../../layout/Form_2_ColLayout/Form_2_ColLayout';
import AddImages from '../../components/AddImages/AddImages';
import { EditorState } from 'draft-js';
import DashboardLayout2 from '../../layout/DashBoardLayout2/DashBoardLayout2';
import { useNavigate, useParams } from 'react-router-dom';
import CurrentImages from '../../components/CurrentImages/CurrentImages';
import BackButton from '../../components/BackButton/BackButton';
import Loader from '../../components/Loader/Loader';

const AddEditPropertyPage = () => {




  const roomsOptions = [{ 0: "Select Value" }, { 1: "One" }, { 2: "Two" }, { 3: "Three" }, { 4: "Four" }, { 5: "5 or More" }]

  const listingType = [{ 0: "Select Value" }, { 1: "Sale" }, { 2: "Rent" }]


  const categoryOptions = [{ "Appartments/flats": "Appartments/flats" }, { "Stand Alone": "Stand Alone" }, { "Land": "Land" }, { "Office": "Office" }, { "Shops": "Shops" }, { "Godowns": "Godowns" }, { "Conference Hall": "Conference Hall" }, { "Gated Community": "Gated Community" }, { "Affordable Housing": "Affordable Housing" }, { "Hostels": "Hostels" }, { "Servised Appartments": "Servised Appartments" }]

  const $ = require("jquery")

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());




  const [price, setPrice] = useState()
  const [title, setTitle] = useState()
  const [rooms, setRooms] = useState()
  const [bathrooms, setBathrooms] = useState()
  const [location, setLocation] = useState()
  const [type, setType] = useState()
  const [size, setSize] = useState()
  const [category, setCategory] = useState()
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [mainImage, setMainImage] = useState()
  const [published, setPublished] = useState(false)
  const [data, setData] = useState()
  const [mainLoading, setMainLoading] = useState(true)

  const navigate  =  useNavigate()

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      setMainLoading(true)
      getProperty(id)
        .then((response) => {
          setData(response.data)
          setPrice(response.data.price)
          setTitle(response.data.title)
          setRooms(response.data.rooms)
          setBathrooms(response.data.bathrooms)
          setLocation(response.data.location)
          setType(response.data.type)
          setSize(response.data.size)
          setCategory(response.data.category)
          setPublished(response.data.published)
          setEditorState(response.data.desc)
        })
        .catch(() => {
          toast.error("An Error Occurred")
        })
        .finally(() => {
          
        })
    }
    setMainLoading(false)
  }, [])


  const handlePublishChange = () => {
    setPublished(!published)
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleRoomsChange = (e) => {
    setRooms(e.target.value)
  }

  const handleBathroomsChange = (e) => {
    setBathrooms(e.target.value)
  }

  const handleLocationChange = (e) => {
    setLocation(e.target.value)
  }

  const handlePriceChange = (e) => {
    setPrice(e.target.value)
  }

  const handleTypeChange = (e) => {
    setType(e.target.value)
  }

  const handleSizeChange = (e) => {
    setSize(e.target.value)
  }

  const handleCategoryChange = (e) => {
    setCategory(e.target.value)
  }

  const onEditorStateChange = (e) => {
    setEditorState(e)
  }



  const selectedImages = (images) => {
    setImages(images)


  }

  const selectMainImage = (images) => {
    setMainImage(images[0])
  }


  const handleCreateProperty = (e) => {
    e.preventDefault()

    const formData = new FormData();

    formData.append("title", title)
    formData.append("rooms", rooms)
    formData.append("bathrooms", bathrooms)
    formData.append("location", location)
    formData.append("category", "All")
    formData.append("size", 20)
    formData.append("price", price)
    formData.append("desc", editorState)
    formData.append("published", published)
    formData.append("category", category)
    formData.append("property_type", type)


    if (data) {

      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {

          formData.append('images[]', images[i]);
        }
      }

      if (mainImage) {
        formData.append("main_image", mainImage)
      }

      setLoading(true)

      updateProperty(id, formData)
        .then(() => {
          toast.success("product update successfully",{
            onClose:()=>{
              navigate('/properties')
            }
          })
        })
        .catch(() => {
          toast.error("An Error Occurred")
          setLoading(false)
        })
    } else {
      if (images.length <= 0) {
        toast.error('Kindly Add atleast one Property image to continue');
        return
      }

      if (!mainImage) {
        toast.error('Main image is required to continue');
        return
      }



      for (let i = 0; i < images.length; i++) {

        formData.append('images[]', images[i]);
      }

      formData.append("main_image", mainImage)

      setLoading(true)
      addProperty(formData)
        .then(() => {
          // myModal.getInstance().hide()
          toast.success('Property added Successfully',{
            onClose:()=>{
              navigate("/properties")
            }
          })
        })
        .catch((error) => {
          toast.error(error);
          setLoading(false)
        })
        
    }
  }

  return (
    <DashboardLayout2>

      {mainLoading
        ?
        <div className='flex w-full h-full justify-center items-center'>
          <Loader></Loader>
        </div>
        :
        <div>

          <div className='flex w-full justify-between px-6'>

            <BackButton path={"/properties"}></BackButton>
          </div>


          <div
            className="flex h-full w-full ">
            <div

              className=" flex w-full xl:mx-40 2xl:mx-60 transition-all duration-300 ease-in-out">
              <div
                className="flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current">
                <div
                  class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                  <div className='flex items-baseline gap-2'>
                    <h5
                      class="text-xl  leading-normal dark:text-neutral-200 font-bold"
                      id="exampleModalLgLabel">
                      Add / Edit Property
                    </h5>
                    <p className='text-gray-400 text-xs '>(items marked with <span className='text-red-500'>*</span> are Required)</p>
                  </div>
                </div>


                <div class="relative p-4">
                  {/* <div className='upload min-h-[200px] bg-gray-300 rounded-lg'> */}
                  <TitleText title='Main image'></TitleText>
                  {data &&
                    <CurrentImages images={[data.main_image_thumbnail_url]}></CurrentImages>
                  }
                  <AddImages setImages={selectMainImage} multiple={false} ></AddImages>
                  <TitleText title='Other images(Multiple)'></TitleText>
                  {data &&
                    <CurrentImages images={data.image_thumbnails_urls}></CurrentImages>
                  }
                  <AddImages setImages={selectedImages}></AddImages>

                  {/* </div> */}
                  <form onSubmit={handleCreateProperty}>
                    <div className='flex flex-col gap-2'>
                      <TitleText title='Property Details'></TitleText>
                    </div>

                    <div className='body flex flex-col gap-6 py-4'>

                      <FormInput label="Property Title" onChange={handleTitleChange} required={true} value={title}></FormInput>

                      <Form2ColLayout>
                        <FormSelect name="rooms" label="Rooms" options={roomsOptions} type='number' onChange={handleRoomsChange} value={rooms}></FormSelect>
                        <FormSelect name="bathrooms" label="Bathrooms" options={roomsOptions} type='number' onChange={handleBathroomsChange} value={bathrooms}></FormSelect>
                      </Form2ColLayout>

                      <Form2ColLayout>
                        <FormInput name="price" label="Price" onChange={handlePriceChange} required={true} value={price} ></FormInput>

                        <FormInput name="location" label="Location" onChange={handleLocationChange} required={true} value={location} ></FormInput>
                      </Form2ColLayout>


                      <Form2ColLayout>
                        <FormSelect name="type" label="Listing Type" options={listingType} required={true} onChange={handleTypeChange} value={type}></FormSelect>
                        <FormInput name="size" label="Size in SQ ft" onChange={handleSizeChange} value={size} type='number'></FormInput>
                      </Form2ColLayout>

                      <FormSelect label="Category" options={categoryOptions} onChange={handleCategoryChange} value={category}></FormSelect>


                      <label className='flex gap-2'>
                        <input type="checkbox" checked={published} onChange={handlePublishChange}></input>
                        published
                      </label>

                      <TitleText title='Properties Description' ></TitleText>


                      <WysiwygEditor onchange={onEditorStateChange} value={editorState}></WysiwygEditor>

                    </div>
                    <div className=' w-full flex justify-center items-center'>
                      <LoadingButton type="submit" text="save" loading={loading}></LoadingButton>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>

        </div >
      }


    </DashboardLayout2>
  )
}

AddEditPropertyPage.propTypes = {};

AddEditPropertyPage.defaultProps = {};

export default AddEditPropertyPage;
