import React from 'react';
import PropTypes from 'prop-types';
import './ViewCounter.css';

const ViewCounter = ({value}) => (
  <div className=' h-8  bg-pri text-white rounded-full flex justify-center gap-2 items-center py-[2px] px-[8px] text-lg max-w-min'>


  <svg className='h-6' viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_102_1415)">
      <path d="M0.493652 5.92383C0.493652 5.92383 2.46826 1.97461 5.92383 1.97461C9.37939 1.97461 11.354 5.92383 11.354 5.92383C11.354 5.92383 9.37939 9.87305 5.92383 9.87305C2.46826 9.87305 0.493652 5.92383 0.493652 5.92383Z" stroke="white" stroke-width="0.987305" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.92383 7.40479C6.74174 7.40479 7.40479 6.74174 7.40479 5.92383C7.40479 5.10592 6.74174 4.44287 5.92383 4.44287C5.10592 4.44287 4.44287 5.10592 4.44287 5.92383C4.44287 6.74174 5.10592 7.40479 5.92383 7.40479Z" stroke="white" stroke-width="0.987305" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_102_1415">
        <rect width="11.8477" height="11.8477" fill="white" />
      </clipPath>
    </defs>
  </svg>


  <p>{value}</p>
  
  </div>
);

ViewCounter.propTypes = {};

ViewCounter.defaultProps = {};

export default ViewCounter;
