import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import './WYSIWYGEditor.css';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { Editor } from "react-draft-wysiwyg";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WysiwygEditor = ({ onchange, value }) => {






  return (

    <div className='flex justify-stretch items-stretch w-full mb-20 min-h-[200px]'>
      <ReactQuill className='w-full' theme="snow" value={value} onChange={onchange} />
    </div>

  );
}

WysiwygEditor.propTypes = {};

WysiwygEditor.defaultProps = {};

export default WysiwygEditor;
