import React from 'react';
import PropTypes from 'prop-types';
import './DropArea.css';

const DropArea = () => (
  <div className="flex w-full flex-col border-dashed rounded-lg border-2 justify-center items-center border-pri py-10">

    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#2F80ED" />
      <path d="M33 28V32C33 32.5304 32.7893 33.0391 32.4142 33.4142C32.0391 33.7893 31.5304 34 31 34H17C16.4696 34 15.9609 33.7893 15.5858 33.4142C15.2107 33.0391 15 32.5304 15 32V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M29 21L24 16L19 21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M24 16V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <p className=" text-sm text-[#00000066]">Drop a file or Click to select</p>
    <p className="text-[10px] text-[#00000066]">*only image files allowed</p>

  </div>
);

DropArea.propTypes = {};

DropArea.defaultProps = {};

export default DropArea;
